import {
  Button,
  Flex,
  FormControl,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { customersApi } from '@texas/api/endpoints/customersApi';
import { ReactSelectOption } from '@texas/types';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { conceptsApi } from '@texas/api/endpoints/conceptsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';
import { Role } from '@texas/api/endpoints/userApi';
import { useActiveSession } from '@texas/hooks/useSession';
import { hasRole } from '@texas/utils/helpers/claimHelpers';
import { reactEvents } from '@bridge/reactEvents';

interface ConceptFormData {
  name: string;
  customerId: number;
}

export function NewConcept({ customerId }: { customerId?: number }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const session = useActiveSession();
  if (
    !hasRole(session.currentUser.role, [
      Role.InternalAdministrator,
      Role.SystemAdministrator,
    ])
  ) {
    return null;
  }

  return (
    <>
      <Button
        className="md-raised md-button md-ink-ripple"
        height={30}
        fontSize={14}
        fontFamily="inherit"
        padding={1.5}
        borderRadius={3}
        onClick={onOpen}
      >
        {t('general.newConcept')}
      </Button>

      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <InnerModal customerId={customerId} onClose={onClose} />
      </Modal>
    </>
  );
}

function InnerModal({
  customerId,
  onClose,
}: {
  customerId?: number;
  onClose: () => void;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const { error, loading, request } = useApiRequest(conceptsApi.addConcept);
  const {
    data: customerList,
    refetch: refetchCustomerList,
    loading: customerListLoading,
  } = useApiResource(customersApi.getAll);
  const [searchInput, setSearchInput] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ConceptFormData>({
    defaultValues: { name: '', customerId: customerId },
  });

  useEffect(() => {
    refetchCustomerList();
  }, [refetchCustomerList]);

  const onSubmit = async (data: ConceptFormData) => {
    const response = await request(data.customerId, {
      name: data.name,
    });
    if (response.hasError) {
      toast({
        title: t('general.createFailed'),
        description: error?.message,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    toast({
      title: t('general.created'),
      status: 'success',
      isClosable: true,
    });
    reset();
    reactEvents.conceptCreated.dispatch();
    onClose();
  };

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader fontWeight="bold">{t('general.newConcept')}</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap={4}>
            <FormControl isRequired={true} isInvalid={!!errors.customerId}>
              <TexasFormLabel>{t('general.brand')}</TexasFormLabel>
              <Controller
                name="customerId"
                control={control}
                render={({ field }) => (
                  <TexasSelect
                    {...field}
                    required={true}
                    isLoading={customerListLoading}
                    placeholder={t('concept.searchBrand')}
                    value={customerList
                      ?.map((customer) => ({
                        value: customer.id,
                        label: customer.name,
                      }))
                      .find((c) => c.value === field.value)}
                    onChange={(option) => {
                      field.onChange(option ? option.value : null);
                    }}
                    onInputChange={(e) => {
                      setSearchInput(e);
                    }}
                    options={
                      searchInput.length === 0
                        ? []
                        : (customerList ?? []).map<ReactSelectOption>((c) => ({
                            label: c.name,
                            value: c.id,
                          }))
                    }
                  />
                )}
              />
              <ErrorLabel text={errors.customerId?.message} />
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors.name}>
              <TexasFormLabel>{t('general.name')}</TexasFormLabel>
              <Input
                variant="outline"
                {...register('name', {
                  required: true,
                  maxLength: {
                    value: 100,
                    message: t('errors.maxLength', { count: 100 }),
                  },
                })}
                placeholder={t('general.name')}
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
            <SubmitButton loading={loading || customerListLoading}>
              {t('general.save')}
            </SubmitButton>
          </Flex>
        </form>
      </ModalBody>
    </ModalContent>
  );
}
