// @ngInject
const scrollTop = ($window) => {
  $window.scrollTo(0, 0);
};

// @ngInject
const customerResolve = ($stateParams, CustomersApiService) =>
  CustomersApiService.getById($stateParams.customerId);

// @ngInject
const rootFolderIdResolve = (customer) => customer.folderId;

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('customer', {
    parent: 'main',
    url: '/customers/{customerId}',
    views: {
      'main-content': {
        controller: 'CustomerController as vm',
        templateUrl: 'main/customer/customer.tpl.html',
      },
    },
    data: { pageTitle: 'Customer' },
    resolve: {
      customer: customerResolve,
    },
    onEnter: scrollTop,
  });
  // Articles tab
  $stateProvider.state('customer.articles', {
    url: '/articles',
    templateUrl: 'tabs/articlesTab/articlesTab.tpl.html',
    controller: 'ArticlesTabController as vm',
    resolve: {
      concept: () => null,
    },
    params: {
      filterByConceptId: null,
    },
  });
  // Concepts tab
  $stateProvider.state('customer.concepts', {
    url: '/concepts',
    templateUrl: 'tabs/conceptsTab/conceptsTab.tpl.html',
    controller: 'ConceptsTabController as vm',
  });
  // Files tab
  $stateProvider.state('customer.files', {
    url: '/files',
    abstract: true,
    templateUrl: 'tabs/filesTab/filesTab.tpl.html',
    controller: 'FilesTabsController as vm',
    resolve: {
      rootFolderId: rootFolderIdResolve,
      isArticle: () => false,
    },
  });
  $stateProvider.state('customer.files.id', {
    url: '/{folderPath:nonURIEncoded}',
    params: {
      folderPath: { value: null, squash: true },
    },
  });
  // Tasks tab
  $stateProvider.state('customer.tasks', {
    url: '/tasks',
    templateUrl: 'tabs/tasksTab/tasksTab.tpl.html',
    controller: 'TasksTabsController as vm',
    resolve: {
      article: () => null,
      concept: () => null,
      tasksTabSettings: () => null,
    },
  });
};

class CustomerController {
  constructor($scope, $state, customer) {
    'ngInject';

    this.$state = $state;
    this.$scope = $scope;
    this.customer = customer;

    this.entityTabs = [
      {
        heading: '<i class="icon icon-settings"></i>',
        tooltip: 'Management',
        route: 'customer.management',
      },
      {
        heading: '<i class="icon icon-folder"></i>',
        tooltip: 'Files',
        route: 'customer.files.id',
      },
      {
        heading: '<i class="icon icon-assignment"></i>',
        tooltip: 'Tasks',
        route: 'customer.tasks',
      },
    ];

    this.contentTabs = [
      {
        isDefault: true,
        active: $state.is('customer'),
        heading: 'Articles',
        route: 'customer.articles',
      },
      {
        heading: 'Concepts',
        route: 'customer.concepts',
      },
      {
        heading: 'Users',
        route: 'customer.users',
      },
      {
        heading: 'Contacts',
        route: 'customer.contacts',
      },
    ];

    $scope.$on('$stateChangeSuccess', () => {
      if (this.$state.is('customer')) {
        this.goToDefaultState();
      }
    });
  }

  goToDefaultState() {
    const initialTab = _.find(this.contentTabs, { isDefault: true });
    if (initialTab) {
      this.$scope.$applyAsync(() => {
        this.$state.go(initialTab.route, initialTab.params(), {
          location: 'replace',
        });
      });
    }
  }
}

angular
  .module('main.customer', [
    'main.customer.management',
    'main.customer.users',
    'main.customer.contacts',
  ])
  .config(config)
  .controller('CustomerController', CustomerController);
